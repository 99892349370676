<template>
  <div class="page-main">
    <div style="margin: 30px auto">
      <nqForm
        ref="form"
        :values="value"
        submitText="点击查询"
        @submit="formSubmit"
        :formList="[
          {
            label: '请输入单号',
            type: 'input',
            key: 'ordernum',
          },
        ]"
      ></nqForm>

      <div class="query-order-box" v-if="visible">
        <iframe
          :src="searchUrl"
          frameborder="0"
          style="width: 100%; height: 100%"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'orderSearch',
  data() {
    return {
      value: { ordernum: '' },
      visible: false,
      searchUrl: ''
    }
  },
  methods: {
    formSubmit(page) {
      this.searchUrl = `https://extcall.17track.net/zh-cn/track?utm_medium=Referral
      &utm_source=86feifan.com#apitype=1&uheight=560&nums=${page.ordernum}&fc=0&
      sc=0&iframeId=trackIframe-822446&rmad=false&rmh=false`
      this.visible = true
    }
  }
}
</script>
<style scoped>
.query-order-box {
  height: 560px;
  margin: 30px 10px;
}
</style>
